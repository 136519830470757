frappe.provide("erpnext.accounts");

// erpnext.accounts.dimensions.setup_child_filters = function(frm, doctype, parentfield, dimension) {
//     if(!["Purchase Invoice Item","Purchase Receipt Item","Purchase Order Item"].includes(doctype) ){
//         let fields = [];

//         if (frappe.meta.has_field(doctype, dimension)) {
//             frappe.model.with_doctype(doctype, () => {
//                 frappe.meta.get_docfields(doctype).forEach((df) => {
//                     if (df.fieldtype === "Link" && df.options === "Account") {
//                         fields.push(df.fieldname);
//                     }
//                 });

//                 frm.set_query(dimension, parentfield, function (doc, cdt, cdn) {
//                     let row = locals[cdt][cdn];
//                     return erpnext.queries.get_filtered_dimensions(row, fields, dimension, doc.company);
//                 });
//             });
//         }
//     }
// };