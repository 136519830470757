erpnext.TransactionController = class CustomTransactionController extends (
	erpnext.TransactionController
) {
	posting_date() {
		var me = this;
		if (this.frm.doc.posting_date) {
			this.frm.posting_date = this.frm.doc.posting_date;
			if (
				(this.frm.doc.doctype == "Sales Invoice" && this.frm.doc.customer) ||
				(this.frm.doc.doctype == "Purchase Invoice" && this.frm.doc.supplier)
			) {
				return frappe.call({
					method: "emotorad_p2p.emotorad_p2p.customizations.party.get_due_date",
					args: {
						posting_date: me.frm.doc.posting_date,
						party_type:
							me.frm.doc.doctype == "Sales Invoice" ? "Customer" : "Supplier",
						bill_date: me.frm.doc.bill_date,
						pr_date:
							me.frm.doc.doctype == "Purchase Invoice" ? me.frm.doc.pr_date : null,
						party:
							me.frm.doc.doctype == "Sales Invoice"
								? me.frm.doc.customer
								: me.frm.doc.supplier,
						company: me.frm.doc.company,
					},
					callback: function (r, rt) {
						if (r.message) {
							me.frm.doc.due_date = r.message;
							refresh_field("due_date");
							frappe.ui.form.trigger(me.frm.doc.doctype, "currency");
							me.recalculate_terms();
						}
					},
				});
			} else {
				frappe.ui.form.trigger(me.frm.doc.doctype, "currency");
			}
		}
	}

	payment_terms_template() {
		var me = this;
		const doc = this.frm.doc;
		if (doc.payment_terms_template && doc.doctype !== "Delivery Note") {
			var posting_date = doc.posting_date || doc.transaction_date;
			frappe.call({
				method: "emotorad_p2p.emotorad_p2p.customizations.accounts_controller.get_payment_terms",
				args: {
					terms_template: doc.payment_terms_template,
					posting_date: posting_date,
					grand_total: doc.rounded_total || doc.grand_total,
					base_grand_total: doc.base_rounded_total || doc.base_grand_total,
					bill_date: doc.bill_date,
					pr_date: doc.doctype == "Purchase Invoice" ? doc.pr_date : null,
				},
				callback: function (r) {
					if (r.message && !r.exc) {
						me.frm.set_value("payment_schedule", r.message);
						const company_currency = me.get_company_currency();
						me.update_payment_schedule_grid_labels(company_currency);
					}
				},
			});
		}
	}

	payment_term(doc, cdt, cdn) {
		const me = this;
		var row = locals[cdt][cdn];
		if (row.payment_term) {
			frappe.call({
				method: "emotorad_p2p.emotorad_p2p.customizations.accounts_controller.get_payment_term_details",
				args: {
					term: row.payment_term,
					bill_date: this.frm.doc.bill_date,
					posting_date: this.frm.doc.posting_date || this.frm.doc.transaction_date,
					grand_total: this.frm.doc.rounded_total || this.frm.doc.grand_total,
					base_grand_total:
						this.frm.doc.base_rounded_total || this.frm.doc.base_grand_total,
					pr_date:
						this.frm.doc.doctype == "Purchase Invoice" ? this.frm.doc.pr_date : null,
				},
				callback: function (r) {
					if (r.message && !r.exc) {
						for (var d in r.message) {
							frappe.model.set_value(cdt, cdn, d, r.message[d]);
							const company_currency = me.get_company_currency();
							me.update_payment_schedule_grid_labels(company_currency);
						}
					}
				},
			});
		}
	}
};